<template>
  <div class="container">
    <div class="row align-items-center justify-content-center content-body">
      <div class="col-12 text-center card-step">
        <div class="card-body">
          <div class="title-ranking-wa text-center mb-3">
            You’re on the list!
          </div>
          <div class="sub-title-rankingwa text-center">
            All the alpha lists will get the reward from Growth.
          </div>
          <div class="sub-title-rankingwa text-center mb-3">
            when the app is launched.
            <font class="text-read" v-b-modal.modal-center>Read more.</font>
          </div>
        </div>

        <!-- content modal -->
            <b-modal id="modal-center" centered size="lg" hide-footer>
              <div class="card-tabel mb-3">
                <div class="title-rankingwaorg text-center mb-3">
                  Alpha access reward
                </div>
                <div class="title-rankmodel text-center mb-5">
                  Every successful registration increases the prize pool by $2.
                </div>
                <table class="mb-3">
                  <tr>
                    <th><span class="title-intable">Rank</span></th>
                    <th>
                      <div class="title-intable">Reward*</div>
                      <span class="sub-intable">KYC required</span>
                    </th>
                  </tr>
                  <tr>
                    <td>1st</td>
                    <td>$500 + 8% of the prize pool</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>$250 + 4% of the prize pool</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>$100 + 2% of the prize pool</td>
                  </tr>
                  <tr>
                    <td>4th-10th</td>
                    <td>$50 + 1% of the prize pool</td>
                  </tr>
                  <tr>
                    <td>Others</td>
                    <td>Shared the rest of the prize pool</td>
                  </tr>
                </table>
                <div class="text-rankmodel mb-3">
                  *When your account has passed the KYC, the reward will be
                  distributed to your account.
                </div>
              </div>
            </b-modal>
        <!-- end content modal -->

        <div class="row align-items-center justify-content-center mb-3">
          <div class="col col-sm-6 col-md-10 col-lg-10 text-center">
            <hr class="new1" />
          </div>
        </div>
        <div class="title-rankingwaorg text-center mb-5">
          Share the link to your lovers and climb up the waitlist
        </div>
        <div class="row align-items-center justify-content-center mb-3">
          <div class="col text-center">
            <div class="input-group mb-3 input-group-lg justify-content-center">
              <input
                v-on:focus="$event.target.select()"
                ref="clone"
                readonly
                :value="text"
                class="card-copy text-center"
              />
              <button @click="copy" class="btn btn-outline-secondary btn-copy">
                <div class="text-btn-copy">Copy</div>
              </button>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mb-3">
          <div class="col col-md-1">
            <img class="img-fluid" src="../assets/images/Logo/facebook.png" />
          </div>
          <div class="col col-md-1">
            <img class="img-fluid" src="../assets/images/Logo/line.png" />
          </div>
          <div class="col col-md-1">
            <img class="img-fluid" src="../assets/images/Logo/instagram.png" />
          </div>
          <div class="col col-md-1">
            <img class="img-fluid" src="../assets/images/Logo/twitter.png" />
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col col-sm-6 col-md-10 col-lg-10 text-center">
            <hr class="new1" />
          </div>
        </div>

        <div class="title-ranking-wa text-center mb-3">
          <font class="text-user">XXX </font> Rank on the waitlist
        </div>

        <div class="row align-items-center justify-content-center mb-5">
          <div class="col col-sm-6 col-md-6 col-lg-5 text-center">
            <div class="card-stepthird input-group-lg">
              <div class="title-card-stepthird">#100</div>
              <div class="text-card-stepthird">Your Rank</div>
            </div>
          </div>
          <div class="col col-sm-6 col-md-6 col-lg-5 text-center">
            <div class="card-stepthird">
              <div class="title-card-stepthird">#2</div>
              <div class="text-card-stepthird">Referrals</div>
            </div>
          </div>
        </div>

        <div class="row align-items-center justify-content-center mb-3">
          <div class="col-6 title-rankingwa">
            <span>Name</span>
          </div>
          <div class="col-4 title-rankingrightwa">
            <span>Referrals</span>
          </div>
        </div>
        <div
          class="row align-items-center justify-content-center mb-3"
          v-for="data in ranking"
        >
          <div class="col-6 text-ranking">
            <span>{{ data.name }}</span>
          </div>
          <div class="col-4 text-rankingright">
            <span>{{ data.referral }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "growth.com/Referral=G13OWT",
      ranking: [
        { name: "user1", referral: 50 },
        { name: "user2", referral: 50 },
        { name: "user3", referral: 50 },
        { name: "user4", referral: 50 },
      ],
    };
  },
  methods: {
    copy() {
      this.$refs.clone.focus();
      document.execCommand("copy");
    },
  },
};
</script>

